<template>
  <div>
    <div class="d-flex flex-row align-items-center" v-if="identities.length > 1">
      <span class="entity-text mr-2">Entity:</span>

      <div class="d-flex flex-row align-items-center" style="gap: 12px">
        <span
          v-for="(item, index) in identities"
          :key="index"
          @click="handleChooseIdentity(item.name)"
          class="entity__option"
          :class="identity === item.name ? 'entity__option--selected' : ''"
          >{{ item.label }}</span
        >
      </div>
    </div>

    <b-modal
      id="entity-notification"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      class="entity-notification"
    >
      <div class="">
        <p class="text-right mb-0 d-none d-md-block">
          <span @click="closeModal" style="cursor: pointer">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.2929 13.2929C13.6834 12.9024 14.3166 12.9024 14.7071 13.2929L20 18.5858L25.2929 13.2929C25.6834 12.9024 26.3166 12.9024 26.7071 13.2929C27.0976 13.6834 27.0976 14.3166 26.7071 14.7071L21.4142 20L26.7071 25.2929C27.0976 25.6834 27.0976 26.3166 26.7071 26.7071C26.3166 27.0976 25.6834 27.0976 25.2929 26.7071L20 21.4142L14.7071 26.7071C14.3166 27.0976 13.6834 27.0976 13.2929 26.7071C12.9024 26.3166 12.9024 25.6834 13.2929 25.2929L18.5858 20L13.2929 14.7071C12.9024 14.3166 12.9024 13.6834 13.2929 13.2929Z"
                fill="#23262F"
              />
              <rect
                x="1"
                y="1"
                width="38"
                height="38"
                rx="19"
                stroke="#E6E8EC"
                stroke-width="2"
              />
            </svg>
          </span>
        </p>
        <p class="notification-title">
          Important Notification
        </p>
        <p class="mb-4 notification-body">
          Based on your selection, you will register for an account with NOZAX
          (SV) LLC, company reg 1055 LLC 2021 with registered address First
          Floor, First St Vincent Bank Ltd Building, James Street, Kingstown,
          St. Vincent and the Grenadines.
          <br />
          NOZAX (SV) LLC is not required to hold any financial services license
          or authorization in St. Vincent and the Grenadines to offer its
          products and services.
        </p>
        <div
          class="d-flex justify-content-center align-items-centers"
          style="gap: 20px"
        >
          <button
            class="d-block d-md-none close-button btn-outline-primary"
            @click="closeModal"
          >
            Close
          </button>
          <b-button
            @click="
              changeIdentity(selecting_identity, () => {
                $router.go(0);
              })
            "
            variant="primary"
            class="notification-button"
          >
            Confirm
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { changeIdentity } from "@/utils/MultipleIdentities.js";

export default {
  data() {
    return {
      identities: [],
      selecting_identity: this.identity,
      desc: {
        nozax_jsc:
          "NOZAX AD is a joint stock company registered in Montenegro under registration number 40009820 and is authorised and regulated by the Montenegrin Capital Market Authority (license number 03/2-5/5-21).",
        nozax_sv:
          "NOZAX (SV) LLC, is a limited liability company registered at Saint Vincent and the Grenadines, with Limited Liability company number 1055 LLC 2021.",
      },
    };
  },

  mounted() {
    this.identities = JSON.parse(localStorage.getItem("APP_ENV")).sort(
      (a, b) => a.order - b.order
    );
  },
  computed: {
    identity() {
      return localStorage.getItem("APP_IDENTITY");
    },
  },
  methods: {
    changeIdentity,
    async handleChooseIdentity(name) {
      if (name == this.identity) return;
      let obj = this.identities.find((i) => i.name == name);
      if (obj.isRequiredPopup) {
        this.selecting_identity = name;
        await this.$nextTick();
        this.$bvModal.show("entity-notification");
      } else {
        this.changeIdentity(name, () => {
          this.$router.go(0);
        });
      }
    },
    closeModal() {
      this.$bvModal.hide("entity-notification");
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
::v-deep .btn:focus {
  box-shadow: none !important;
}
.entity__dropdown ::v-deep .dropdown-menu {
  background: #ffffff;
  border: 1px solid #e6e8ec;
  box-shadow: 0px 16px 64px -48px rgba(31, 47, 70, 0.15);
  border-radius: 8px;
  padding: 24px 16px;
}

.entity-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #333e44;
  letter-spacing: -0.01em;
}

.entity__option {
  font-style: normal;
  padding: 10px 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.entity__option--selected {
  border-bottom: 2px solid #07b53b;
}

.entity__title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 16px;
}
.entity__desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #616a70;
  margin-bottom: 24px;
}

.entity__button {
  border: 2px solid #07b53b;
  border-radius: 90px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.02em;
  width: 230px;
  height: 48px;
  color: #39454c;
}

.entity__wrapper:not(:last-child) {
  margin-bottom: 40px;
}
::v-deep .vs__dropdown-toggle {
  border: 0;
  height: unset;
  width: 180px;
  height: 28px;
}
::v-deep .vs__search {
  width: 0;
  height: 0;
}
::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;
}

::v-deep .vs__selected {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 744px) {
    font-size: 14px;
  }
}
::v-deep .vs__selected-option {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  @media screen and (max-width: 744px) {
    font-size: 14px;
  }
}

.entity-notification ::v-deep .modal-content {
  border-radius: 20px;
}
.entity-notification ::v-deep .modal-body {
  padding: 24px !important;
}
.notification-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #484c57;
  @media screen and (max-width: 744px) {
    margin: 24px 0;
  }
  @media screen and (max-width: 745px) {
    margin: 32px 0;
  }
}

.notification-body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #484c57;
}

.notification-button {
  border-radius: 90px;
  padding: 16px 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #fcfcfd;
  font-weight: 700;
  font-size: 16px;
  width: 148px;
  line-height: 16px;
}
.close-button {
  border-radius: 90px;
  padding: 16px 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #39454c;
  font-weight: 700;
  font-size: 16px;
  width: 148px;
  line-height: 16px;
}
@media screen and (max-width: 744px) {
  ::v-deep .modal-content {
    padding: 24px;
  }
  .notification__button--outline {
    color: var(--success);
    transition: all 0.3s ease-in-out;
    :hover {
      color: #fcfcfd;
    }
  }
}
.entity__dropdown ::v-deep .btn-link {
  padding-left: 4px !important;
  padding-right: 8px !important;
}
</style>
