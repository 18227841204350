<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <!-- Brand logo-->
        <b-link class="brand-logo" style="left: 50%; right: 50%; top: 114px">
          <vuexy-logo />
          <!-- <h2 class="brand-text ml-1 my-auto">
            {{ companySettings.company_name }}
          </h2> -->
        </b-link>

        <b-col sm="7" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome to {{ companySettings.company_name }}! 👋
          </b-card-title>
          <b-card-text class="mb-3 text-black">
            Please sign-in to your account and manage your business
          </b-card-text>

          <!-- Choose entity -->

          <!-- <identity-selector /> -->

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { mapGetters } from "vuex";
import IdentitySelector from "./IdentitySelector.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    IdentitySelector,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
    };
  },
  computed: {
    ...mapGetters({
      faviconURL: "settings/companyFaviconURL",
      me: "auth/me",
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    companySettings() {
      return store.getters["settings/settingsByPath"](["company"]).settings || {};
    }
  },

  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then(async (response) => {
              useJwt.setToken(response.headers.authorization.split(" ")[1]);
              await this.login_success();
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Login ",
                  icon: "Times",
                  variant: error.response.status === 403 ? "danger" : "warning",
                  text: (error.response?.data?.error || error.response?.data?.errors?.message || error)
                   + (error.response.status === 403 ? `. Please contact us at ${this.companySettings?.support_email} for more information` : ""),
                },
              });
            });
        }
      });
    },
    
    async login_from_token(token) {
      useJwt.setToken(token);
      await this.login_success();
    },

    async login_success() {
      await this.$store.dispatch("auth/fetchMe");
      this.$ability.update([
        {
          action: "manage",
          subject: "all",
        },
      ]);

      // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      this.$router
        .replace(
          getHomeRouteForLoggedInUser(
            "admin",
            this.$route.params.come_from
          )
        ) //userData.role
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Welcome ${this.me.full_name}`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully logged in as partner. Now you can start to explore!`,
            },
          });
        });
    },
  },
  
  async created() {
    console.log("token", this.$route.query?.token)
    if (this.$route.query?.token) {
      let loading = this.$loading.show();
      try {
        await this.login_from_token(this.$route.query?.token);
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Login ",
            icon: "Times",
            variant: "warning",
            text:
              error.response?.data?.error ||
              error.response?.data?.errors?.message ||
              error,
          },
        });
      }
      loading.hide();
    }
    if (this.$route.params.errors) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$route.params.errors,
          icon: "AlertCircleIcon",
          variant: "warning",
        },
      });
    }

    await store.dispatch("settings/fetchCompanyFavicon");
    document.getElementById("favicon").href =
      store.getters["settings/companyFaviconURL"];
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
.login-form-size {
  padding: 71px !important;
  height: 100vh;
  width: 30vw;
  min-width: 480px;
}
@import "@core/scss/vue/pages/page-auth.scss";
</style>
